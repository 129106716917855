import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CustomerSidebarComponent } from './sidebar.component';
import { AngularMaterialModule } from 'src/shared/modules/angular-material/angular-material.module';

@NgModule({
  declarations: [CustomerSidebarComponent],
  imports: [FormsModule, AngularMaterialModule, RouterModule],
  exports: [CustomerSidebarComponent],
})
export class SidebarModule {}
