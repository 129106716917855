import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { UserManagementService } from 'src/app/admin-portal/modules/user-management/services/user-management.service';
import { SignInService } from 'src/app/sign-in/services/sign-in.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
})
export class CustomerLayoutComponent implements OnInit {
  isSidebarOpen = false;
  isDrawerOpen = false;
  drawerWidth = 200;
  isActive = false;
  userName = '';
  userEmail = '';
  portalType = '';
  userID: string = '';

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private signInService: SignInService,
    private userService: UserManagementService
  ) {}

  @ViewChild('sidenav') sidenav!: MatSidenav;
  @Output() newItemEvent = new EventEmitter<boolean>();

  ngOnInit(): void {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    this.userID = user?.userID;

    this.fetchUserDetails();
  }

  fetchUserDetails(): void {
    this.userService.getUserById(this.userID).subscribe({
      next: (res) => {
        const userData = res.data;
        this.userName = userData.name;
        this.userEmail = userData.email;
        this.portalType = userData.portolType;
      },
    });
  }

  handleToggleSidebar(event: any) {
    this.isSidebarOpen = !this.isSidebarOpen;
    this.newItemEvent.emit(this.isSidebarOpen);
    this.isActive = !this.isActive;
  }
  onDrawerChange(opened: boolean): void {
    this.isDrawerOpen = opened;
  }

  toggleSidebarAndClass(event: any) {
    this.sidenav.toggle();
    this.handleToggleSidebar(event);
  }

  goToSettings() {
    this.router.navigate(['customer/profile/setting']);
  }

  logout() {
    this.signInService.logout();
    this.router.navigateByUrl('/sign-in');
  }

  getDefaultPhotoText(userName: string): string {
    if (!userName) return '';

    const firstChar = userName.charAt(0);
    const indexOfFirstSpace = userName.indexOf(' ');
    let secondChar = '';
    if (indexOfFirstSpace !== -1 && indexOfFirstSpace < userName.length - 1) {
      secondChar = userName.charAt(indexOfFirstSpace + 1);
    }

    return `${firstChar}${secondChar}`.toUpperCase();
  }
}
