<mat-sidenav-container
  class="softobiz-sidenav customer-sidenav"
  autosize
  [class.side-nav-open]="isActive"
>
  <mat-sidenav
    #sidenav
    mode="side"
    #drawer
    (openedChange)="onDrawerChange($event)"
  >
    <div class="border-2 side-nav-bar">
      <div class="side-nav-logo" *ngIf="!sidebarOpen">
        <img src="/images/customerLogo.png" alt="profile" />
      </div>
      <div class="collapse-side-nav-logo" *ngIf="sidebarOpen">
        <img src="/images/account-logo.png" />
      </div>
      <mat-list role="list" class="w-full">
        <mat-list-item
          role="listitem"
          [class.active-list]="selectedItemId === 'item1'"
          (click)="selectItem('item1')"
        >
          <div class="sidebar-menu cursor-pointer">
            <img src="/images/icons/space_dashboard.svg" />
            <span>Dashboard</span>
          </div>
        </mat-list-item>

        <mat-list-item
          role="listitem"
          [class.active-list]="selectedItemId === 'item2'"
          (click)="selectItem('item2')"
        >
          <div class="sidebar-menu cursor-pointer">
            <img src="/images/icons/group.svg" />
            <span>Team Management</span>
          </div>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          [class.active-list]="selectedItemId === 'item3'"
          (click)="selectItem('item3')"
        >
          <div class="sidebar-menu cursor-pointer">
            <img src="/images/icons/article_shortcut.svg" />
            <span>Open Positions</span>
          </div>
        </mat-list-item>
        <mat-list-item
          role="listitem"
          [class.active-list]="selectedItemId === 'item4'"
          (click)="selectItem('item4')"
        >
          <div class="sidebar-menu cursor-pointer">
            <img src="/images/icons/contract.svg" />
            <span>Invoicing</span>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
