import { Component, Input } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
})
export class CustomerSidebarComponent {
  selectedItemId: string | null = null;
  @Input() sidebarOpen: boolean = false;
  isDrawerOpen = false;
  isActive = false;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.route.url
      .pipe(map((segments) => segments.join('/')))
      .subscribe((url) => {
        this.setSelectedItem(url);
      });

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.router.url.split(/[?#]/)[0])
      )
      .subscribe((url) => {
        this.setSelectedItem(url);
      });
  }

  setSelectedItem(url: string): void {
    if (url.startsWith('/customer/dashboard')) {
      this.selectedItemId = 'item1';
    } else if (url.startsWith('/customer/team-management')) {
      this.selectedItemId = 'item2';
    } else if (url.startsWith('/customer/open-position')) {
      this.selectedItemId = 'item3';
    } else if (url.startsWith('/customer/invoicing')) {
      this.selectedItemId = 'item4';
    } else {
      this.selectedItemId = null;
    }
  }

  toggleClass(): void {
    this.isActive = !this.isActive;
  }

  selectItem(itemId: string): void {
    this.selectedItemId = itemId;
    switch (itemId) {
      case 'item1':
        this.router.navigate(['/customer/dashboard']);
        break;
      case 'item2':
        this.router.navigate(['/customer/team-management']);
        break;
      case 'item3':
        this.router.navigate(['/customer/open-position']);
        break;
      case 'item4':
        this.router.navigate(['/customer/invoicing']);
        break;
      default:
        break;
    }
  }

  onDrawerChange(opened: boolean): void {
    this.isDrawerOpen = opened;
  }
}
